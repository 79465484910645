.range_date_picker {
  width: 100%;
  border-radius: 6px;

  :global {
    .rdrDateDisplayWrapper {
      display: none;
    }

    .rdrMonthAndYearWrapper {
      padding-top: 0;
      height: auto;

      .rdrMonthPicker,
      .rdrYearPicker {
        margin: 0px;
      }
    }

    .rdrMonth {
      padding: 0px 10px 10px 10px;
      width: 100%;
    }

    .rdrWeekDays {
      margin-bottom: 15px;

      .rdrWeekDay {
        font-size: 10px;
        line-height: 12px;
      }
    }

    .rdrDays {
      justify-content: space-between;
      gap: 12px 0px;

      .rdrDay {
        display: flex;
        width: calc(100% / 7);
        height: 0;
        padding-bottom: calc(100% / 7);
        color: theme("colors.cloudBurst");
        font-size: 14px;
        line-height: 17px;
        font-weight: bold;

        &:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span {
          color: theme("colors.cloudBurst");
        }

        .rdrStartEdge,
        .rdrInRange,
        .rdrEndEdge {
          width: 100%;
          height: 100%;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        .rdrStartEdge,
        .rdrEndEdge {
          &:not(.rdrInRange) {
            border-radius: 50%;
          }
        }

        .rdrStartEdge:not(.rdrEndEdge)::before {
          content: "";
          position: absolute;
          top: 0px;
          right: 0px;
          bottom: 0px;
          width: 50%;
          background-color: theme("colors.pelorous / 0.4");
        }

        .rdrEndEdge:not(.rdrStartEdge)::before {
          content: "";
          position: absolute;
          top: 0px;
          left: 0px;
          bottom: 0px;
          width: 50%;
          background-color: theme("colors.pelorous / 0.4");
        }
      }

      .rdrInRange {
        background-color: theme("colors.pelorous / 0.4");
      }

      .rdrDayNumber span::after {
        display: none;
      }
    }
  }

  &.modal_filter {
    border: none;
    box-shadow: 0 25px 50px -12px theme("colors.black / 0.25");

    :global {
      .rdrMonth {
        padding: 0px 24px 54px 24px;
        width: 100%;
      }
    }
  }

  &.disabled :global(.rdrDays) {
    pointer-events: none;
  }
}

.datepicker_custom_controls {
  span {
    flex: 1 1 auto;
    text-align: center;
  }

  span:not(:last-of-type) {
    position: relative;

    &:after {
      content: "";
      position: absolute;
      top: 4px;
      right: 0;
      width: 1px;
      height: 14px;
      background-color: theme("colors.cloudBurst");
      opacity: 0.4;
    }
  }
}
