// override default styling
.billing_table_wrapper {
  :global {
    .MuiTableContainer-root .MuiTable-root {
      .MuiTableHead-root .MuiTableRow-root {
        &:first-child .MuiTableCell-root {
          background-color: theme("colors.endeavour");
          color: theme("colors.white");
        }

        &:nth-child(2) .MuiTableCell-root {
          background-color: theme("colors.solitude2");
        }
      }

      .MuiTableHead-root,
      .MuiTableBody-root {
        .MuiTableRow-root {
          .MuiTableCell-root {
            padding-inline: 12px;

            &:not(:last-child) {
              position: relative;

              &::after {
                content: "";
                position: absolute;
                top: 0;
                bottom: 0;
                right: 1px;
                width: 1px;
                background-color: theme("colors.linkWater");
                z-index: 1;
              }
            }
          }
        }
      }
    }
  }

  &.limit_cell_widths {
    :global {
      .MuiTableContainer-root .MuiTable-root {
        .MuiTableHead-root,
        .MuiTableBody-root {
          .MuiTableRow-root {
            .MuiTableCell-root {
              max-width: 0;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;

              &:not(:last-child) {
                position: relative;

                &::after {
                  content: "";
                  position: absolute;
                  top: 0;
                  bottom: 0;
                  right: 1px;
                  width: 1px;
                  background-color: theme("colors.linkWater");
                  z-index: 1;
                }
              }

              &:nth-child(8)::after {
                display: none;
              }

              &:last-child {
                padding-inline: 5px;
              }

              // account column
              &:nth-child(1) {
                width: 190px;
                max-width: 190px;
              }

              // dob, billing start, billing stop columns
              &:nth-child(3),
              &:nth-child(4),
              &:nth-child(5) {
                width: 115px;
                max-width: 115px;
              }

              // order id column
              &:nth-child(6) {
                width: 150px;
                max-width: 150px;
              }

              // days used column
              &:nth-child(7) {
                text-align: center;
                width: 95px;
                max-width: 95px;
              }

              // status column
              &:nth-child(8) {
                overflow: unset;
                width: 200px;
                max-width: 200px;
              }

              // pdf summary actions column
              &:nth-child(9) {
                width: 75px;
                max-width: 75px;
              }
            }
          }
        }
      }
    }

    @media (width < theme("screens.xl")) {
      :global {
        .MuiTableContainer-root .MuiTable-root {
          .MuiTableHead-root,
          .MuiTableBody-root {
            .MuiTableRow-root .MuiTableCell-root {
              max-width: unset;
            }
          }
        }
      }
    }
  }

  .comment_btn {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 4px;
    display: flex;
    align-items: center;

    path {
      &:first-child {
        color: theme("colors.white");
        opacity: 1;
      }

      &:last-child {
        color: theme("colors.pelorous");
      }
    }

    &:hover path:first-child {
      color: theme("colors.pelorous");
    }
  }
}
