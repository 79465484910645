@mixin dropdown_wrapper {
  :global {
    .MuiInputBase-root {
      border-radius: 6px;
      background-color: theme("colors.white");
      color: theme("colors.pelorous");
      font-family: "Cabin", sans-serif;
      font-size: 16px;
      font-weight: 500;

      .MuiSelect-select {
        padding: 9px 32px 7px 10px;
      }

      .MuiOutlinedInput-notchedOutline {
        transition: border-color 0.15s ease-in-out;
      }

      &:not(.Mui-focused) {
        .MuiOutlinedInput-notchedOutline,
        &:hover .MuiOutlinedInput-notchedOutline,
        &.Mui-disabled .MuiOutlinedInput-notchedOutline {
          border-width: 1px;
          border-color: theme("colors.linkWater");
        }
      }

      &.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: theme("colors.pelorous");
      }

      .Mui-disabled {
        -webkit-text-fill-color: theme("colors.pelorous");
      }
    }
  }
}
