.percent_text {
  position: absolute;
  top: 32px;
  bottom: 47px;
  left: 10px;
  opacity: 0.5;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: theme("colors.cloudBurst");
  font-size: 14px;
  line-height: 17px;
  font-weight: 500;

  :nth-child(3) {
    writing-mode: vertical-lr;
    transform: rotate(180deg);
  }
}

.color_bar {
  position: absolute;
  top: 40px;
  bottom: 51px;
  left: 85px;
  width: 21px;
  border: 2px solid theme("colors.white");
  background: linear-gradient(0deg,
      theme("colors.persianRed") 0%,
      theme("colors.confetti") 45%,
      theme("colors.apple") 100%);
}