.rates_container {
  :global {
    .MuiRadio-root {
      padding: 0px;
      width: 20px;
      height: 20px;

      .MuiSvgIcon-root {
        color: theme("colors.pelorous");
      }

      circle {
        color: theme("colors.white");
      }

      &.Mui-checked {
        position: relative;

        &::after {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 4px;
          height: 4px;
          border-radius: 50%;
          background-color: theme("colors.white");
        }
      }
    }
  }
}
