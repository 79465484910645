.cognitive_table {
  :global {
    .MuiTableHead-root .MuiTableRow-root {
      &:last-child .MuiTableCell-root {
        background-color: theme("colors.endeavour");
        color: theme("colors.white");
        position: relative;

        &:not(:last-child):not(:nth-child(8)):after {
          content: "";
          position: absolute;
          top: 0px;
          right: -1px;
          bottom: 0px;
          width: 1px;
          background-color: theme("colors.linkWater");
          z-index: 9;
        }

        &:not(:first-child) {
          text-align: center;

          &:not(:nth-child(2))::after {
            top: 9px;
            bottom: 9px;
          }
        }
      }
    }

    .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root {
      padding: 10px 20px;
      vertical-align: top;

      &:not(:nth-child(2)) {
        background-color: theme("colors.pattensBlue2");
      }

      &:not(:first-child) {
        text-align: center;
      }

      &:last-child {
        width: 150px;
      }
    }
  }

  .accession_label {
    font-size: 10px;
  }
}
