.switch_wrapper {
  display: flex;
  align-items: center;
  gap: 5px;

  :global {
    .MuiSwitch-root {
      width: 36px;
      height: 20px;
      padding: 0px;
      display: flex;

      .MuiSwitch-switchBase {
        padding: 2px;

        &.Mui-checked {
          transform: translateX(16px);
          color: theme("colors.white");

          + .MuiSwitch-track {
            opacity: 1;
            background-color: theme("colors.dodgerBlue");
          }
        }

        &.Mui-disabled {
          opacity: 0.5;

          + .MuiSwitch-track {
            opacity: 0.5;
          }
        }
      }

      .MuiSwitch-thumb {
        width: 16px;
        height: 16px;
        border-radius: 16px;
        box-shadow: 0 2px 4px 0 #00000033;
        transition: width 0.2s ease-in-out;
      }

      .MuiSwitch-track {
        box-sizing: border-box;
        border-radius: 16px;
        opacity: 1;
        background-color: theme("colors.silver");
      }
    }
  }
}
