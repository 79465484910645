.date_input {
  :global {
    .MuiInputBase-root.Mui-disabled {
      cursor: pointer;

      .MuiInputBase-input {
        pointer-events: none;
        -webkit-text-fill-color: unset;
      }
    }
  }

  &.not_empty {
    :global {
      .MuiInputBase-input::placeholder {
        color: theme("colors.cloudBurst");
      }
    }
  }

  &.open:not(:hover) {
    :global {
      .MuiInputBase-input {
        color: theme("colors.pelorous");

        &::placeholder {
          color: theme("colors.pelorous");
        }
      }

      .MuiInputBase-root.MuiInput-underline,
      .MuiInputBase-root.MuiInput-underline:hover {
        &::before {
          border-bottom-color: theme("colors.pelorous");
        }

        &::after {
          border-bottom-color: theme("colors.pelorous");
        }
      }

      .MuiInputAdornment-root .MuiButtonBase-root .icon_cross {
        background-color: theme("colors.pelorous");
      }
    }
  }
}

.calendar_wrapper {
  :global {
    .MuiPaper-root {
      border-radius: 0 9px 9px 9px;

      .rdrDateDisplayWrapper {
        display: none;
      }

      .rdrMonthAndYearWrapper {
        height: auto;
        padding-top: 13px;
      }

      .rdrMonth {
        padding: 8px;

        .rdrDay {
          font-size: 14px;
          line-height: 22px;
        }
      }
    }
  }
}
