@import "styles/shared/selectMenuRootMixin.scss";
@import "styles/shared/dropdownWrapperMixin.scss";
@import "styles/shared/inputFieldMixin.scss";

.dropdown_multiselection_wrapper {
  @include dropdown_wrapper;

  .value_container {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &.input_field_style {
    @include input_field;

    :global {
      .MuiInputBase-root .MuiInputBase-input {
        padding-block: 10px 7px;
      }
    }
  }
}

.menu_root {
  @include select_menu_root;
}
