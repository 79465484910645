.pelvic_table {
    :global {
        .MuiTableHead-root {
            .MuiTableRow-root {
                &:first-child {
                    .MuiTableCell-root {
                        border-radius: 0px;

                        &:first-of-type {
                            border-radius: 6px 0px 0px 0px !important;
                        }

                        &:nth-of-type(2) {
                            border-radius: 0px 6px 0px 0px !important;

                        }
                    }
                }

                &:nth-of-type(2) {
                    .MuiTableCell-root {
                        &:nth-of-type(4) {
                            &:before {
                                visibility: hidden;
                            }
                        }
                    }
                }
            }
        }

        .MuiTableRow-root:nth-of-type(2),
        .MuiTableBody-root {
            .MuiTableCell-root {
                &:nth-of-type(4) {
                    border-right: 1px solid theme("colors.linkWater");
                }
            }
        }
    }
}