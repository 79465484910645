@import "styles/shared/selectMenuRootMixin.scss";
@import "styles/shared/dropdownWrapperMixin.scss";
@import "styles/shared/inputFieldMixin.scss";

.dropdown_wrapper {
  @include dropdown_wrapper;
}

.input_field_wrapper {
  @include input_field;

  :global {
    .MuiInputBase-root .MuiInputBase-input {
      padding-block: 10px 7px;
      font-weight: 500;

      &.Mui-disabled {
        opacity: 0.5;
      }
    }
  }
}

.select_root {
  @include select_menu_root;
}
