@import "../../styles/shared/searchInputFieldMixin.scss";

.autocomplete {
  @include search_input_field;

  :global {
    .MuiTextField-root .MuiInputBase-root {
      padding: 0 5px 0 0 !important;

      .MuiInputBase-input {
        padding-left: 14px;
      }

      .MuiOutlinedInput-notchedOutline {
        border-color: theme("colors.pelorous");
      }

      &.Mui-error .MuiOutlinedInput-notchedOutline {
        border-color: theme("colors.crimson");
      }
    }

    .MuiFormHelperText-root {
      content: "";
      position: absolute;
      right: 0;
      top: 0;
      transform: translateY(-100%) translateY(-3px);
      margin: 0;
      font-family: "Cabin", sans-serif;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: normal;
      display: flex;
      align-items: center;
      gap: 5px;

      &.Mui-error {
        color: theme("colors.crimson");
      }
    }
  }
}

.autocomplete_menu_popper {
  :global {
    .MuiPaper-root {
      margin-top: 5px;
      border-radius: 6px;
      box-shadow: 0px 9px 46px 8px theme("colors.black / 12%"),
        0px 24px 38px 3px theme("colors.black / 14%"),
        0px 11px 15px -7px theme("colors.black / 20%");

      .MuiAutocomplete-loading {
        padding-block: 10px 5px;
      }

      .MuiAutocomplete-noOptions {
        font-family: "Cabin";
        font-size: 16px;
        line-height: 18px;
        font-weight: 500;
      }

      .MuiAutocomplete-listbox {
        padding: 0;

        .MuiAutocomplete-option {
          padding: 10px;
          color: theme("colors.cloudBurst");
          font-size: 16px;
          line-height: 18px;
          font-weight: 500;

          &[aria-selected="true"],
          &.Mui-focused {
            background-color: unset;
          }

          &:hover {
            background-color: theme("colors.black / 4%");
          }

          &:not(:last-child) {
            border-bottom: 1px solid theme("colors.linkWater");
          }
        }
      }
    }
  }
}
