.shared_table_container {
  :global {
    max-height: 100%;
    overflow: scroll;
    overscroll-behavior: none;
    border-radius: 6px 6px 0px 0px;
    border: 1px solid theme("colors.linkWater");
    border-bottom: none;

    .MuiTable-root {
      .MuiTableHead-root,
      .MuiTableBody-root {
        .MuiTableRow-root {
          .MuiTableCell-root {
            font-family: "Cabin", sans-serif;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            white-space: nowrap;
          }
        }
      }

      .MuiTableHead-root {
        position: sticky;
        top: 0;
        z-index: 999;

        .MuiTableRow-root {
          &:first-child .MuiTableCell-root {
            background-color: theme("colors.linkWater");
            color: theme("colors.cloudBurst");
          }

          .MuiTableCell-root {
            border-bottom: none;
            padding: 6px 20px;
          }
        }
      }

      .MuiTableBody-root {
        .MuiTableCell-root {
          background-color: theme("colors.white / 50%");
          border-bottom-color: theme("colors.linkWater");
          color: theme("colors.cloudBurst");
        }
      }
    }
  }

  @media (max-width: theme("screens.xl")) {
    :global {
      .MuiTable-root {
        .MuiTableBody-root {
          .MuiTableRow-root {
            .MuiTableCell-root:first-child {
              white-space: initial;
            }
          }
        }
      }
    }
  }

  @media (width < theme("screens.xl")) {
    :global {
      .MuiTable-root {
        .MuiTableBody-root .MuiTableRow-root,
        .MuiTableHead-root .MuiTableRow-root {
          .MuiTableCell-root {
            &:first-child {
              position: sticky;
              left: 0;
            }
          }
        }

        .MuiTableHead-root {
          .MuiTableRow-root {
            .MuiTableCell-root:first-child {
              z-index: 9999;
            }
          }
        }

        .MuiTableBody-root {
          .MuiTableRow-root {
            .MuiTableCell-root {
              font-size: 16px;
              line-height: 18px;

              &:first-child {
                font-weight: bold;
                background-color: theme("colors.pattensBlue2");

                &::before,
                &::after {
                  content: " ";
                  height: 100%;
                  position: absolute;
                  top: 0;
                  width: 15px;
                }
                &::before {
                  box-shadow: -15px 0 15px -15px inset;
                  left: -15px;
                }
                &::after {
                  box-shadow: 15px 0 15px -15px inset;
                  right: -15px;
                }
              }
            }
          }
        }
      }
    }
  }

  &.padding_16_20 {
    :global {
      .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root {
        padding: 16px 20px;
      }
    }
  }

  &.padding_20 {
    :global {
      .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root {
        padding: 20px;
      }
    }
  }

  @media (width < theme("screens.md")) {
    :global {
      .MuiTable-root .MuiTableRow-root .MuiTableCell-root:first-child {
        max-width: 150px;
        white-space: normal;
        overflow-wrap: break-word;
      }
    }
  }
}
