.all_types_table {
  :global {
    .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root {
      padding: 20px;
      vertical-align: top;
    }

    .MuiTableHead-root {
      .MuiTableCell-root:last-of-type {
        text-align: center;
      }
    }
  }
}
