.client_autocomplete {
  width: 500px;
  max-width: 50vw;

  :global {
    .MuiTextField-root .MuiInputBase-root {
      color: theme("colors.pelorous");
      border-bottom-right-radius: 0px;

      .MuiOutlinedInput-notchedOutline {
        border-color: theme("colors.white");
      }
    }
  }
}
