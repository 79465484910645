@mixin radio_selection {
  :global {
    .MuiFormGroup-root {
      display: flex;
      flex-direction: row;
      gap: 35px;

      .MuiFormControlLabel-root {
        margin: 0;
        display: flex;
        gap: 5px;

        .MuiButtonBase-root {
          padding: 0;
          color: theme("colors.pelorous");

          circle {
            color: theme("colors.white");
          }

          &.Mui-checked {
            position: relative;

            &::after {
              content: "";
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              width: 4px;
              height: 4px;
              border-radius: 50%;
              background-color: theme("colors.white");
            }
          }
        }

        .MuiTypography-root {
          font-family: "Cabin", sans-serif;
          font-size: 16px;
          line-height: 18px;
          font-weight: 500;
          color: theme("colors.pelorous");
        }
      }
    }
  }
}
