.gait_table {
  :global {
    .MuiTableHead-root .MuiTableRow-root {
      &:first-child .MuiTableCell-root:not(:first-child) {
        text-align: center;
      }

      &:last-child .MuiTableCell-root {
        background-color: theme("colors.endeavour");
        color: theme("colors.white");
        position: relative;

        &:not(:first-child) {
          text-align: center;
        }

        &:not(:last-child):not(:nth-child(6)):after {
          content: "";
          position: absolute;
          top: 0px;
          right: -1px;
          bottom: 0px;
          width: 1px;
          background-color: theme("colors.linkWater");
          z-index: 9;
        }

        &:last-child {
          width: 150px;
        }
      }
    }

    .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root {
      padding: 10px 20px;
      vertical-align: top;

      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5) {
        font-size: 21px;
        line-height: 24px;
      }

      &:not(:first-child) {
        text-align: center;
      }

      &:nth-child(odd):not(:last-child) {
        background-color: theme("colors.pattensBlue2");
      }
    }
  }

  .accession_label {
    font-size: 10px;
  }
}
