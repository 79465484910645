.sidebar_item_accordion {
  flex-direction: column;
}

.sidebar_item_accordion,
.sidebar_item {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  padding: 9px 0px 9px 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  color: theme("colors.white");
  font-weight: bold;
  transition: background-color 0.3s ease-in-out;

  &.active {
    background-color: theme("colors.pattensBlue / 20%");

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      content: "";
      width: 3px;
      height: 100%;
      background: radial-gradient(
        50% 50% at 50% 50%,
        theme("colors.white") 18.75%,
        theme("colors.malibu") 100%
      );
    }
  }

  .submenu_items_wrapper {
    position: relative;

    &:before {
      content: "";
      position: absolute;
      top: -13px;
      left: 10px;
      width: 2px;
      height: 97%;
      background-color: theme("colors.pattensBlue / 0.2");
    }

    a {
      width: 80%;
      padding: 6px 16px;
      position: relative;
      text-align: left;

      &:before {
        content: "";
        position: absolute;
        top: 17px;
        left: -16px;
        height: 2px;
        width: 10px;
        background-color: theme("colors.pattensBlue / 0.2");
      }

      &.active {
        background-color: theme("colors.pattensBlue / 0.2");
        border-radius: 9px 0px 0px 9px;
        box-shadow: 0px 4px 4px 0px theme("colors.black / 0.25");
      }
    }
  }

  @media (max-width: theme("screens.sm")),
    (width < theme("screens.lg")) and (orientation: landscape) {
    background-color: theme("colors.pattensBlue / 20%");
    font-size: 21px;
    line-height: 25px;
    padding: 16px 0px 16px 28px;

    &.active::before {
      content: none;
    }

    .submenu_items_wrapper {
      &::before {
        display: none;
      }

      a {
        width: 100%;
        padding: 8px 16px 8px 35px;

        &::before {
          display: none;
        }
      }
    }
  }

  @media (width < theme("screens.lg")) and (orientation: landscape) {
    justify-content: center;
  }
}
