.arrow_box {
  position: relative;

  &:after,
  &:before {
    left: 100%;
    top: 100px;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &:after {
    border-left-color: theme("colors.aliceBlue2");
    border-width: 15px;
    margin-top: -15px;
  }

  &:before {
    border-left-color: theme("colors.pelorous");
    border-width: 16px;
    margin-top: -16px;
  }

  @media (max-width: theme("screens.md")) {
    &:after,
    &:before {
      top: 100%;
      left: 50%;
    }

    &:after {
      border-left-color: transparent;
      margin-top: 0;
      border-top-color: theme("colors.aliceBlue2");
      margin-left: -15px;
    }

    &:before {
      border-left-color: transparent;
      margin-top: 0;
      border-top-color: theme("colors.pelorous");
      margin-left: -16px;
    }
  }
}
