@import "../../../styles/shared/selectMenuRootMixin.scss";
@import "../../../styles/shared/inputFieldMixin.scss";

.select_wrapper {
  @include input_field;

  :global {
    .MuiInputBase-root {
      padding-right: 35px;

      .MuiInputBase-input {
        padding-block: 10px 7px;
      }
    }
  }
}

.select_root {
  @include select_menu_root;
}
