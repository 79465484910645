.menu_wrapper {
  :global {
    .MuiPaper-root {
      border-radius: 6px;

      .MuiList-root {
        padding: 0px;

        .MuiMenuItem-root {
          display: flex;
          justify-content: flex-end;
          padding: 10px 15px;
          font-family: "Cabin", sans-serif;
          font-size: 16px;
          line-height: 20px;

          &:hover {
            font-weight: 500;
            background-color: theme("colors.pelorous / 0.3");
          }

          &:not(:last-child) {
            border-bottom: 1px solid theme("colors.linkWater");
          }

          &:first-child {
            padding-top: 15px;
          }

          &:last-child {
            padding-bottom: 15px;
          }
        }
      }
    }
  }
}
