@mixin select_menu_root {
  :global {
    .MuiPaper-root {
      margin-top: 10px;
      border-radius: 6px;
      box-shadow: 0px 9px 46px 8px theme("colors.black / 12%"),
        0px 24px 38px 3px theme("colors.black / 14%"),
        0px 11px 15px -7px theme("colors.black / 20%");

      .MuiList-root {
        padding-block: 0px;

        .MuiMenuItem-root {
          white-space: pre;
          display: flex;
          align-items: center;
          gap: 5px;
          padding: 10px;
          color: theme("colors.pelorous");
          font-family: "Cabin", sans-serif;
          font-size: 16px;
          font-weight: 500;

          &[aria-selected="true"],
          &.Mui-focusVisible {
            background-color: unset;
          }

          &:hover {
            background-color: theme("colors.black / 4%");
          }

          &:not(:last-child) {
            border-bottom: 1px solid theme("colors.linkWater");
          }

          .MuiCheckbox-root,
          .MuiRadio-root {
            padding: 0px;
            width: 20px;
            height: 20px;

            .MuiSvgIcon-root {
              color: theme("colors.pelorous");
            }
          }

          .MuiRadio-root {
            circle {
              color: theme("colors.white");
            }

            &.Mui-checked {
              position: relative;

              &::after {
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 4px;
                height: 4px;
                border-radius: 50%;
                background-color: theme("colors.white");
              }
            }
          }
        }
      }
    }
  }
}
