@mixin search_input_field {
  :global {
    .MuiInputBase-root {
      border-radius: 76px;
      background-color: theme("colors.white");
      box-sizing: border-box;
      height: 42px;
      padding-right: 5px;
      color: theme("colors.cloudBurst");
      font-family: "Cabin", sans-serif;
      font-size: 16px;
      font-weight: 500;

      .MuiInputBase-input {
        &::placeholder {
          color: theme("colors.cloudBurst");
        }
      }

      &:hover .MuiOutlinedInput-notchedOutline,
      .MuiOutlinedInput-notchedOutline {
        transition: border-color 0.15s ease-in-out,
          border-width 0.1s ease-in-out;
        border-color: transparent;
      }

      &.Mui-focused {
        color: theme("colors.pelorous");

        .MuiOutlinedInput-notchedOutline {
          border-color: theme("colors.pelorous");
        }
      }
    }
  }
}
