.white_label {
  :global {
    .MuiTableSortLabel-root {
      &:hover,
      &.Mui-active {
        color: theme("colors.white");
      }
    }
  }
}
