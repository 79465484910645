.snackbar_wrapper {
  position: absolute;
  bottom: auto;
  top: 34px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;

  :global {
    .MuiSnackbar-root {
      position: relative;
      top: auto;
      right: auto;
      bottom: auto;
      left: auto;

      .MuiPaper-root {
        z-index: 20;
        min-width: unset;
        padding: 10px;
        border-radius: 6px;
        color: theme("colors.cloudBurst");
        font-size: 14px;
        line-height: 17px;
        font-weight: 500;

        .MuiSnackbarContent-message {
          padding: 0px;
          text-align: center;
        }
      }
    }
  }

  &.success {
    :global {
      .MuiSnackbar-root .MuiPaper-root {
        border: 1px solid theme("colors.tropicalRainForest");
        background-color: theme("colors.onahau");
      }
    }
  }

  &.error {
    :global {
      .MuiSnackbar-root .MuiPaper-root {
        border: 1px solid theme("colors.crimson");
        background-color: theme("colors.snuff");
      }
    }
  }
}
