.input_field {
  width: 100%;

  :global {
    .MuiInputBase-input {
      height: 26px;
      box-sizing: border-box;
      color: theme("colors.cloudBurst");
      font-family: "Cabin", sans-serif;
      font-size: 14px;
      line-height: 17px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      &::placeholder {
        color: theme("colors.cloudBurst / 50%");
        opacity: 1;
        font-weight: 500;
      }
    }

    .MuiInputBase-root.MuiInput-underline,
    .MuiInputBase-root.MuiInput-underline:hover {
      &::before {
        border-bottom: 2px solid theme("colors.cloudBurst");
      }

      &::after {
        border-bottom: 2px solid theme("colors.cloudBurst");
      }
    }

    .MuiInputAdornment-root .MuiButtonBase-root {
      padding: 3px;

      .icon_cross {
        width: 10px;
        height: 10px;
        background-color: theme("colors.cloudBurst");
        transition: all 100ms ease-in-out;
        transition-property: width, height, transform;
      }

      &:hover {
        background-color: theme("colors.cloudBurst");

        .icon_cross {
          transform: scale(0.8);
          background-color: theme("colors.white");
        }
      }
    }
  }

  &.empty:not(:hover) {
    :global {
      .MuiInputBase-root.MuiInput-underline,
      .MuiInputBase-root.MuiInput-underline:hover {
        &::before {
          border-bottom-color: theme("colors.cloudBurst / 50%");
        }

        &::after {
          border-bottom: 1px solid theme("colors.cloudBurst / 50%");
        }
      }
    }
  }
}
