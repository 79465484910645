.icon_svg {
  mask-repeat: no-repeat;
  mask-position: center center;
  display: inline-block;
  position: relative;
  mask-size: contain;
}

.icon_visibility {
  mask-image: url("../assets/icons/icon_visibility.svg");
  width: 29px;
  height: 25px;
  background-color: theme("colors.black");
}

.icon_visibility_off {
  mask-image: url("../assets/icons/icon_visibility_off.svg");
  width: 29px;
  height: 25px;
  background-color: theme("colors.black");
}

.icon_billing {
  mask-image: url("../assets/icons/icon_billing.svg");
  width: 24px;
  height: 24px;
  background-color: theme("colors.white");
}

.icon_kpis {
  mask-image: url("../assets/icons/icon_kpis.svg");
  width: 24px;
  height: 23px;
  background-color: theme("colors.white");
}

.icon_account {
  mask-image: url("../assets/icons/icon_account.svg");
  width: 24px;
  height: 24px;
  background-color: theme("colors.white");
}

.icon_patients {
  mask-image: url("../assets/icons/icon_patients.svg");
  width: 24px;
  height: 24px;
  background-color: theme("colors.white");
}

.icon_clinicians {
  mask-image: url("../assets/icons/icon_clinicians.svg");
  width: 24px;
  height: 24px;
  background-color: theme("colors.white");
}

.icon_orders {
  mask-image: url("../assets/icons/icon_orders.svg");
  width: 24px;
  height: 24px;
  background-color: theme("colors.white");
}

.icon_profile {
  mask-image: url("../assets/icons/icon_profile.svg");
  width: 24px;
  height: 24px;
  background-color: theme("colors.white");
}

.icon_admin {
  mask-image: url("../assets/icons/icon_admin.svg");
  width: 24px;
  height: 24px;
  background-color: theme("colors.white");
}

.icon_operations {
  mask-image: url("../assets/icons/icon_operations.svg");
  width: 24px;
  height: 19px;
  background-color: theme("colors.white");
}

.icon_log_out {
  mask-image: url("../assets/icons/icon_log_out.svg");
  width: 24px;
  height: 24px;
  background-color: theme("colors.white");
}

.icon_menu {
  mask-image: url("../assets/icons/icon_menu.svg");
  width: 21px;
  height: 16px;
  background-color: theme("colors.white");
}

.icon_star {
  mask-image: url("../assets/icons/icon_star.svg");
  width: 24px;
  height: 24px;
  background-color: theme("colors.pelorous");
}

.icon_star_filled {
  mask-image: url("../assets/icons/icon_star_filled.svg");
  width: 24px;
  height: 24px;
  background-color: theme("colors.pelorous");
}

.icon_cross {
  mask-image: url("../assets/icons/icon_cross.svg");
  width: 14px;
  height: 14px;
  background-color: theme("colors.pelorous");
}

.icon_search {
  mask-image: url("../assets/icons/icon_search.svg");
  width: 16px;
  height: 16px;
  background-color: theme("colors.white");
}

.icon_navigate_arrow {
  mask-image: url("../assets/icons/icon_navigate_arrow.svg");
  width: 7px;
  height: 12px;
  background-color: theme("colors.cloudBurst");
}

.icon_no_results {
  mask-image: url("../assets/icons/icon_no_results.svg");
  width: 32px;
  height: 27px;
  background-color: theme("colors.white");
}

.icon_more {
  mask-image: url("../assets/icons/icon_more.svg");
  width: 4px;
  height: 22px;
  background-color: theme("colors.cloudBurst");
}

.icon_filter {
  mask-image: url("../assets/icons/icon_filter.svg");
  width: 18px;
  height: 19px;
  background-color: theme("colors.pelorous");
}

.icon_filter_solid {
  mask-image: url("../assets/icons/icon_filter_solid.svg");
  width: 18px;
  height: 19px;
  background-color: theme("colors.white");
}

.icon_print {
  mask-image: url("../assets/icons/icon_print.svg");
  width: 24px;
  height: 24px;
  background-color: theme("colors.pelorous");
}

.icon_download {
  mask-image: url("../assets/icons/icon_download.svg");
  width: 33px;
  height: 24px;
  background-color: theme("colors.pelorous");
}

.icon_plus {
  mask-image: url("../assets/icons/icon_plus.svg");
  width: 8px;
  height: 7px;
  background-color: theme("colors.white");
}

.icon_minus {
  mask-image: url("../assets/icons/icon_minus.svg");
  width: 6px;
  height: 2px;
  background-color: theme("colors.white");
}

.icon_new_orders {
  mask-image: url("../assets/icons/icon_new_orders.svg");
  width: 24px;
  height: 24px;
  background-color: theme("colors.white");
}

.icon_pending_shipment {
  mask-image: url("../assets/icons/icon_pending_shipment.svg");
  width: 24px;
  height: 24px;
  background-color: theme("colors.white");
}

.icon_all_sensors {
  mask-image: url("../assets/icons/icon_all_sensors.svg");
  width: 24px;
  height: 24px;
  background-color: theme("colors.white");
}

.icon_warehouse {
  mask-image: url("../assets/icons/icon_warehouse.svg");
  width: 24px;
  height: 24px;
  background-color: theme("colors.white");
}

.icon_home {
  mask-image: url("../assets/icons/icon_home.svg");
  width: 24px;
  height: 24px;
  background-color: theme("colors.white");
}

.icon_at_customer {
  mask-image: url("../assets/icons/icon_at_customer.svg");
  width: 24px;
  height: 24px;
  background-color: theme("colors.white");
}

.icon_in_transit {
  mask-image: url("../assets/icons/icon_in_transit.svg");
  width: 24px;
  height: 24px;
  background-color: theme("colors.white");
}

.icon_new_tab {
  mask-image: url("../assets/icons/icon_new_tab.svg");
  width: 12px;
  height: 12px;
  background-color: theme("colors.pelorous");
}

.icon_download_2 {
  mask-image: url("../assets/icons/icon_download_2.svg");
  width: 18px;
  height: 18px;
  background-color: theme("colors.tropicalRainForest");
}

.icon_logout {
  mask-image: url("../assets/icons/icon_logout.svg");
  width: 24px;
  height: 24px;
  background-color: theme("colors.white");
}

.icon_folder {
  mask-image: url("../assets/icons/icon_folder.svg");
  width: 111px;
  height: 92px;
  background-color: theme("colors.white");
}

.icon_arrow_down {
  mask-image: url("../assets/icons/icon_arrow_2.svg");
  width: 10px;
  height: 10px;
  background-color: theme("colors.white");
}

.icon_arrow_up {
  mask-image: url("../assets/icons/icon_arrow_2.svg");
  width: 10px;
  height: 10px;
  background-color: theme("colors.white");
  -webkit-transform: rotateX(180deg);
  transform: rotateX(180deg);
}

.icon_arrow {
  mask-image: url("../assets/icons/icon_arrow.svg");
  width: 8px;
  height: 18px;
  background-color: theme("colors.white");
}

.icon_long_arrow {
  mask-image: url("../assets/icons/icon_long_arrow.svg");
  width: 10px;
  height: 60px;
  background-color: theme("colors.cloudBurst");
}

.icon_table_section {
  mask-image: url("../assets/icons/icon_table_section.svg");
  width: 23px;
  height: 23px;
  background-color: theme("colors.pelorous");
}

.icon_minus_2 {
  mask-image: url("../assets/icons/icon_minus_2.svg");
  width: 11px;
  height: 1px;
  background-color: theme("colors.white");
}

.icon_check {
  mask-image: url("../assets/icons/icon_check.svg");
  width: 24px;
  height: 21px;
  background-color: theme("colors.white");
}

.icon_edit {
  mask-image: url("../assets/icons/icon_edit.svg");
  width: 18px;
  height: 18px;
  background-color: theme("colors.pelorous");
}

.icon_delete {
  mask-image: url("../assets/icons/icon_delete.svg");
  width: 18px;
  height: 18px;
  background-color: theme("colors.crimson");
}

.icon_view_pdf {
  background-image: url("../assets/icons/icon_view_pdf.svg");
  width: 26px;
  height: 26px;
}

.icon_download_pdf {
  background-image: url("../assets/icons/icon_download_pdf.svg");
  width: 26px;
  height: 32px;
}
