@import "../../../styles/shared/selectMenuRootMixin.scss";

.section_select {
  background-color: theme("colors.white");
  border-radius: 6px !important;

  :global {
    .MuiSelect-select {
      display: flex;
      padding: 8px 11px;
    }

    .MuiOutlinedInput-notchedOutline {
      border: 1px solid theme("colors.linkWater") !important;
    }

    .MuiSelect-icon {
      color: theme("colors.cloudBurst");
    }
  }

  &:hover {
    :global {
      .MuiOutlinedInput-notchedOutline {
        border-color: theme("colors.linkWater") !important;
      }
    }
  }
}

.select_menu_root {
  @include select_menu_root;

  :global {
    .MuiPaper-root {
      width: 324px;
      max-width: 90vw;
    }
  }
}
