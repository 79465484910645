@mixin input_field {
  :global {
    .MuiInputBase-root {
      border-radius: 9px;
      background-color: theme("colors.aliceBlue");
      color: theme("colors.cloudBurst");
      font-family: "Cabin", sans-serif;
      font-size: 16px;
      line-height: 19px;

      .MuiInputBase-input {
        padding: 9px 10px 8px 10px;
      }

      .MuiOutlinedInput-notchedOutline {
        border-color: transparent;
        transition: border-color 0.15s ease-in-out,
          border-width 0.1s ease-in-out;
      }

      &.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-width: 2px;
      }

      &:hover,
      &.Mui-disabled {
        &:not(.Mui-error):not(.Mui-focused) .MuiOutlinedInput-notchedOutline {
          border-color: transparent;
        }
      }

      &.Mui-error .MuiOutlinedInput-notchedOutline {
        border-color: theme("colors.crimson");
      }

      &.Mui-focused:not(.Mui-error) .MuiOutlinedInput-notchedOutline {
        border-color: theme("colors.pelorous");
      }

      &.MuiInputBase-multiline {
        padding: 9px 10px 8px 10px;

        .MuiInputBase-input {
          padding: 0;
        }
      }
    }

    .MuiFormHelperText-root {
      content: "";
      position: absolute;
      right: 0;
      top: 0;
      transform: translateY(-100%) translateY(-3px);
      margin: 0;
      font-family: "Cabin", sans-serif;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: normal;
      display: flex;
      align-items: center;
      gap: 10px;

      &.Mui-error {
        color: theme("colors.crimson");
      }
    }
  }
}
