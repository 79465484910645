.test_results_table_container {
  :global {
    .MuiTable-root {
      .MuiTableCell-root {
        text-align: center;
        font-family: "Cabin", sans-serif;
        font-size: 14px;
        line-height: 17px;
        font-weight: 500;
      }

      .MuiTableHead-root .MuiTableRow-root {
        &:first-child {
          .MuiTableCell-root {
            border-radius: 6px 6px 0px 0px;
            background-color: theme("colors.white");
            color: theme("colors.cloudBurst");
          }
        }

        &:last-child .MuiTableCell-root {
          border-radius: unset;
          position: relative;
          background-color: theme("colors.endeavour");
          color: theme("colors.white");

          &:not(:last-child)::before {
            content: "";
            position: absolute;
            top: 9px;
            right: 0px;
            bottom: 9px;
            width: 1px;
            background-color: theme("colors.linkWater");
          }
        }

        .MuiTableCell-root {
          padding: 6px 20px;
        }
      }

      .MuiTableBody-root .MuiTableCell-root {
        padding: 10px 20px 12px 20px;
        background-color: theme("colors.white");
        color: theme("colors.black");
      }
    }
  }
}