.profile_form {
    :global {
        .MuiFormControl-root {
            width: 100%;
        }

        .MuiInputBase-root {
            height: 40px;
            border-radius: 9px;
            background-color: theme("colors.aliceBlue");

            .MuiInputBase-input {
                box-sizing: border-box;
                padding: 11px 10px 10px 10px;
                color: theme("colors.cloudBurst");
                font-family: "Cabin", sans-serif;
                font-size: 16px;
                line-height: 19px;
                font-weight: 500;
            }

            .MuiOutlinedInput-notchedOutline,
            &:hover .MuiOutlinedInput-notchedOutline {
                border-color: transparent;

                legend {
                    width: 0px;
                }
            }

            &.Mui-focused {
                .MuiInputBase-input {
                    color: theme("colors.pelorous");

                    &::placeholder {
                        color: theme("colors.cloudBurst");
                    }
                }

                .MuiOutlinedInput-notchedOutline {
                    border-color: theme("colors.pelorous");
                }
            }

            &.Mui-error .MuiOutlinedInput-notchedOutline {
                border-color: theme("colors.crimson");
            }
        }

        .MuiTextField-root .MuiFormHelperText-root {
            margin: 0px;
            position: absolute;
            top: 0px;
            right: 0px;
            transform: translateY(-22px);
            color: theme("colors.crimson");
            font-family: "Cabin", sans-serif;
            font-size: 16px;
            line-height: 19px;
            letter-spacing: normal;
            -webkit-text-security: none;
            display: flex;
            align-items: center;
            gap: 5px;

            .MuiSvgIcon-root {
                width: 19px;
                height: 19px;
                color: theme("colors.malibu");
            }
        }

        .MuiInputBase-adornedEnd {
            padding-right: 3px;

            .MuiInputAdornment-root .MuiIconButton-root span:first-child {
                width: 23px;
                height: 21px;
            }
        }
    }

    .hide_password {
        -webkit-text-security: disc;
    }
}