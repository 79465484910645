.accordion_wrapper {
  :global {
    .MuiPaper-root {
      box-shadow: none;
      margin: 0;

      &::before {
        display: none;
      }

      .MuiAccordionSummary-root {
        padding: 0px;
        min-height: unset;

        &.Mui-expanded {
          min-height: unset;
        }

        .MuiAccordionSummary-content {
          margin: 10px 0px 6px;
          color: theme("colors.pelorous");
          font-size: 16px;
          line-height: 20px;
          font-weight: bold;
        }

        .MuiAccordionSummary-expandIconWrapper {
          margin-right: 18px;

          &.Mui-expanded span {
            background-color: theme("colors.pelorous");
          }
        }
      }

      .MuiAccordionDetails-root {
        padding: 0px;
      }

      &:not(:last-child) {
        border-bottom: 1px solid theme("colors.prussianBlue / 0.1");
      }
    }
  }

  &.assign_sensor_modal {
    :global {
      .MuiPaper-root {
        .MuiAccordionSummary-root {
          border-bottom: 1px solid theme("colors.prussianBlue / 0.1");

          .MuiAccordionSummary-content {
            font-size: 18px;
            line-height: 22px;
          }

          .MuiAccordionSummary-expandIconWrapper span {
            background-color: theme("colors.pelorous");
          }
        }

        .MuiAccordionDetails-root {
          padding-top: 10px;
        }
      }
    }
  }
}
