.chat_icon {
  width: 20px;
  height: 20px;

  path {
    &:first-child {
      color: theme("colors.white");
      opacity: 1;
    }

    &:last-child {
      color: theme("colors.pelorous");
    }
  }
}
