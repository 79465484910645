@import "styles/shared/selectMenuRootMixin.scss";

.cell_billing_dropdown_wrapper {
  :global {
    .MuiInputBase-root {
      position: absolute;
      inset: 0;
      color: theme("colors.cloudBurst");
      font-size: 14px;
      line-height: 17px;
      font-weight: 500;
      font-family: "Cabin", sans-serif;

      .MuiSelect-select {
        border-radius: 0px;
        padding-right: 55px !important;
        box-sizing: border-box;
        height: 100%;

        &:hover,
        &[aria-expanded="true"] {
          background-color: theme("colors.white");
          box-shadow: 0px 5px 5px 0px #00000050;
          text-decoration: underline;

          &[aria-expanded="true"] {
            color: theme("colors.pelorous");
          }
        }
      }

      .MuiOutlinedInput-notchedOutline {
        display: none;
      }

      .MuiSelect-icon {
        right: 35px;
        color: theme("colors.cloudBurst");

        &.MuiSelect-iconOpen {
          color: theme("colors.pelorous");
        }
      }
    }
  }
}

.filter_billing_dropdown_wrapper {
  :global {
    .MuiInputBase-root {
      width: 100%;

      .MuiInputBase-input {
        padding-right: 0px !important;
        height: 26px;
        box-sizing: border-box;
        color: theme("colors.cloudBurst");
        font-family: "Cabin", sans-serif;
        font-size: 14px;
        line-height: 17px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        &:focus {
          background-color: transparent;
        }
      }

      &.MuiInput-underline,
      &.MuiInput-underline:hover {
        &::before {
          border-bottom: 2px solid theme("colors.cloudBurst");
        }

        &::after {
          border-bottom: 2px solid theme("colors.cloudBurst");
        }
      }

      .MuiInputAdornment-root .MuiButtonBase-root {
        padding: 3px;

        .icon_cross {
          width: 10px;
          height: 10px;
          background-color: theme("colors.cloudBurst");
          transition: all 100ms ease-in-out;
          transition-property: width, height, transform;
        }

        &:hover {
          background-color: theme("colors.cloudBurst");

          .icon_cross {
            transform: scale(0.8);
            background-color: theme("colors.white");
          }
        }
      }

      .MuiSelect-icon {
        display: none;
      }
    }
  }

  &.empty {
    :global {
      .MuiInputBase-root {
        .MuiInputBase-input {
          color: theme("colors.cloudBurst / 50%");
          font-weight: 500;
        }

        &:not(:hover) {
          &.MuiInput-underline,
          &.MuiInput-underline:hover {
            &::before {
              border-bottom-color: theme("colors.cloudBurst / 50%");
            }

            &::after {
              border-bottom: 1px solid theme("colors.cloudBurst / 50%");
            }
          }
        }
      }
    }
  }
}

.menu_root {
  @include select_menu_root;
}
