.checkbox_input {
  width: fit-content;
  display: flex;
  margin: 0 !important;
  gap: 10px;

  :global {
    .MuiCheckbox-root {
      align-self: start;
      padding: 0;
      height: 20px;
      width: 20px;

      .MuiSvgIcon-root {
        color: theme("colors.pelorous");
      }

      &.Mui-disabled {
        opacity: 0.5;
      }
    }

    .MuiTypography-root {
      color: theme("colors.cloudBurst");
      font-family: "Cabin", sans-serif;
      font-size: 14px;
      line-height: 17px;
      font-weight: 500;
    }
  }

  &.test_param_input {
    align-items: start;
    gap: 5px;

    :global {
      .MuiTypography-root {
        font-size: 16px;
        line-height: 19px;
        color: theme("colors.pelorous");
      }
    }
  }
}
