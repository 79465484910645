.login_input_wrapper {
  :global {
    .MuiInputBase-root {
      background-color: theme("colors.white");
      border-radius: 15px;
      height: 62px;

      .MuiInputBase-input {
        box-sizing: border-box;
        height: 100%;
        text-align: center;
        font-family: "Cabin", sans-serif;
        color: theme("colors.cloudBurst");
        font-size: 16px;
        line-height: 19px;
        font-weight: 500;
      }

      &.Mui-focused .MuiInputBase-input {
        color: theme("colors.pelorous");
      }

      fieldset,
      &:hover fieldset {
        border-color: theme("colors.pattensBlue");
      }

      &.Mui-focused fieldset,
      &.Mui-focused:hover fieldset {
        border: 2px solid theme("colors.pelorous");
      }

      &.Mui-error fieldset,
      &.Mui-error.Mui-focused:hover fieldset {
        border: 2px solid theme("colors.crimson");
      }

      &.MuiOutlinedInput-root {
        padding-right: 0;

        .MuiInputBase-input {
          padding: 16px 50px;
        }
      }

      .MuiInputAdornment-positionEnd {
        position: absolute;
        top: auto;
        right: 5px;

        .MuiSvgIcon-root {
          color: theme("colors.black");
        }
      }
    }
  }
}
