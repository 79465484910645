.patient_progress_table {
  :global {
    .MuiTable-root .MuiTableHead-root .MuiTableRow-root {
      .MuiTableCell-root:nth-child(4),
      .MuiTableCell-root:nth-child(5) {
        text-align: center;
      }
    }
  }
}
