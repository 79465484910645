.interval_toggle_controls {
  span {
    flex: 1 1 auto;
    text-align: center;
    padding: 0px 10px;
  }

  span:not(:last-of-type) {
    position: relative;

    &:after {
      content: "";
      position: absolute;
      top: 4px;
      right: 0;
      width: 1px;
      height: 14px;
      background-color: theme("colors.cloudBurst");
      opacity: 0.4;
    }
  }
}