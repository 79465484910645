.search_field {
  :global {
    .MuiInputBase-root,
    .MuiInputBase-root:hover {
      .MuiOutlinedInput-notchedOutline {
        border-color: theme("colors.pelorous");
      }
    }
  }
}
