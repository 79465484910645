.testing_accordion_wrapper {
  :global {
    .MuiPaper-root {
      box-shadow: none;
      margin: 0;

      &.MuiAccordion-root.MuiAccordion-rounded {
        overflow: auto;
        border-radius: 6px;
        border: 1px solid theme("colors.pelorous");
      }

      &::before {
        display: none;
      }

      .MuiAccordionSummary-root {
        padding: 15px 23px 15px 15px;
        min-height: unset;
        background-color: theme("colors.white");
        transition: background-color 150ms ease-in-out;

        &.Mui-expanded {
          background-color: theme("colors.aliceBlue");
          min-height: unset;
        }

        .MuiAccordionSummary-content {
          margin: 0px;

          .MuiFormControlLabel-root .MuiTypography-root {
            font-size: 16px;
            line-height: 19px;
          }
        }

        .MuiAccordionSummary-expandIconWrapper span {
          background-color: theme("colors.pelorous");
        }
      }

      .MuiAccordionDetails-root {
        border-top: 1px solid theme("colors.pelorous");
        padding: 20px;
      }

      &:not(:last-child) {
        border-bottom: 1px solid theme("colors.prussianBlue / 0.1");
      }
    }
  }

  &.error {
    :global {
      .MuiPaper-root.MuiAccordion-root.MuiAccordion-rounded {
        border-color: theme("colors.crimson");

        .MuiAccordionDetails-root {
          border-top-color: theme("colors.crimson");
        }
      }
    }
  }
}
