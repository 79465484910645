.notes_link {
  display: flex;
  align-items: center;
  gap: 10px;
  color: theme("colors.pelorous");
  font-size: 14px;
  line-height: 17px;
  font-weight: 500;

  .chat_icon_wrapper {
    position: relative;

    svg {
      width: 33px;
      height: 33px;

      path {
        &:first-child {
          color: theme("colors.white");
          opacity: 1;
        }

        &:last-child {
          color: theme("colors.pelorous");
        }
      }
    }

    .count {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, calc(-50% - 3px));
    }
  }

  &:hover {
    text-decoration: underline;

    .chat_icon_wrapper {
      svg path:first-child {
        color: theme("colors.pelorous");
      }

      &::after {
        position: absolute;
        content: "+";
        top: 50%;
        left: 50%;
        transform: translate(-50%, calc(-50% - 3px));
        color: theme("colors.white");
        font-size: 25px;
        line-height: 29px;
      }
    }
  }
}
