.account_form_tests_wrapper {
  border-radius: 6px;
  background-color: theme("colors.aliceBlue");

  :global {
    .MuiFormControlLabel-root {
      padding: 10px 8px;
      width: 100%;
      border-bottom: 1px solid theme("colors.linkWater");

      &:last-of-type {
        border-bottom: none;
      }
    }

    .MuiFormControlLabel-label {
      font-size: 16px;
    }
  }
}
