.table_wrapper {
  :global {
    .MuiTableContainer-root {
      max-height: 100%;
      overflow: scroll;
      overscroll-behavior: none;
      padding-right: 20px;

      .MuiTable-root {
        .MuiTableHead-root {
          position: sticky;
          top: 0;
          z-index: 999;

          .MuiTableRow-root {
            .MuiTableCell-root {
              border-bottom: none;
              padding: 6px 10px;
              font-family: "Cabin", sans-serif;
              font-weight: 500;
              font-size: 14px;
              line-height: 17px;
              white-space: nowrap;
            }

            &:first-child .MuiTableCell-root {
              background-color: theme("colors.aliceBlue3");
              color: theme("colors.cloudBurst");
              padding-top: 8px;
              font-weight: 700;

              &:first-child {
                background-color: theme("colors.linkWater");
                border-top-left-radius: 6px;
              }

              &:not(:first-child) {
                position: relative;

                &::after {
                  content: "";
                  position: absolute;
                  top: 0;
                  left: 0;
                  right: 0;
                  height: 1px;
                  background-color: theme("colors.linkWater");
                }
              }
            }

            &:nth-child(2) .MuiTableCell-root {
              background-color: theme("colors.endeavour");
              color: theme("colors.white");
            }

            &:nth-child(3) .MuiTableCell-root {
              background-color: theme("colors.solitude2");
              position: relative;

              &::before {
                content: "";
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                height: 1px;
                background-color: theme("colors.linkWater");
              }
            }

            &:nth-child(2) .MuiTableCell-root,
            &:nth-child(3) .MuiTableCell-root {
              &:nth-child(1)::after {
                content: "";
                position: absolute;
                top: 0;
                bottom: 0;
                right: -1px;
                width: 1px;
                background-color: theme("colors.linkWater");
              }
            }
          }
        }

        .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root {
          padding: 17px 10px;
          background-color: theme("colors.white / 50%");
          border-bottom-color: theme("colors.linkWater");
          color: theme("colors.cloudBurst");
          font-family: "Cabin", sans-serif;
          font-weight: 500;
          font-size: 14px;
          line-height: 17px;
          white-space: nowrap;

          &:first-child {
            background-color: theme("colors.pattensBlue2");
            border-right: 1px solid theme("colors.white");

            &::before,
            &::after {
              content: " ";
              height: 100%;
              position: absolute;
              top: 0;
              width: 15px;
            }
            &::before {
              box-shadow: -15px 0 15px -15px inset;
              left: -15px;
            }
            &::after {
              box-shadow: 15px 0 15px -15px inset;
              right: -15px;
            }
          }
        }

        .MuiTableBody-root .MuiTableRow-root,
        .MuiTableHead-root .MuiTableRow-root {
          .MuiTableCell-root {
            min-width: 150px;

            &:first-child {
              min-width: 200px;
              position: sticky;
              left: 0;
              z-index: 5;
            }

            &:not(:first-child):not(:nth-child(2)) {
              border-left: 1px solid theme("colors.linkWater");
            }

            &:last-child {
              border-right: 1px solid theme("colors.linkWater");
            }
          }
        }
      }
    }
  }
}
