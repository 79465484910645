.angles_table {
  :global {
    .MuiTableHead-root .MuiTableRow-root {
      &:last-child .MuiTableCell-root {
        background-color: theme("colors.endeavour");
        color: theme("colors.white");
        position: relative;

        &:not(:last-child):not(:nth-child(9)):after {
          content: "";
          position: absolute;
          top: 0px;
          right: -1px;
          bottom: 0px;
          width: 1px;
          background-color: theme("colors.linkWater");
          z-index: 9;
        }
      }
    }

    .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root {
      padding: 10px 20px;
      vertical-align: top;

      &:nth-child(1),
      &:nth-child(3),
      &:nth-child(5),
      &:nth-child(7) {
        background-color: theme("colors.pattensBlue2");
      }

      &:last-child {
        width: 150px;
      }
    }
  }

  .accession_label {
    font-size: 10px;
  }
}
