.kpi_table_container {
  :global {
    overflow: scroll;
    overscroll-behavior: none;
    border-radius: 6px 6px 0px 0px;
    border: 1px solid theme("colors.linkWater");
    border-bottom: none;

    .MuiTable-root {
      .MuiTableHead-root,
      .MuiTableBody-root {
        .MuiTableRow-root {
          .MuiTableCell-root {
            box-sizing: border-box;
            font-family: "Cabin", sans-serif;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            text-align: center;

            &:first-child {
              text-align: left;
            }
          }
        }
      }

      .MuiTableHead-root {
        position: sticky;
        top: 0;
        z-index: 999;

        .MuiTableRow-root {
          .MuiTableCell-root {
            border-bottom: none;
            white-space: pre;

            &:not(:last-child) {
              border-right: 1px solid theme("colors.white");
            }

            .MuiTableSortLabel-icon {
              position: absolute;
              left: 100%;
            }
          }

          &:first-child .MuiTableCell-root {
            background-color: theme("colors.linkWater");
            color: theme("colors.cloudBurst");
            padding-block: 5px;
            height: 29px;
          }

          &:nth-child(2) .MuiTableCell-root {
            background-color: theme("colors.endeavour");
            color: theme("colors.white");
            height: 49px;
            padding: 0px 7px;
          }
        }
      }

      .MuiTableBody-root .MuiTableRow-root {
        .MuiTableCell-root {
          height: 52px;
          background-color: theme("colors.white / 50%");
          border-bottom-color: 1px solid theme("colors.linkWater");
          color: theme("colors.cloudBurst");

          &:not(:last-child) {
            border-right: 1px solid theme("colors.linkWater");
          }
        }
      }
    }
  }

  &.last_row_total {
    :global {
      .MuiTable-root
        .MuiTableBody-root
        .MuiTableRow-root:last-child
        .MuiTableCell-root {
        color: theme("colors.endeavour");
        font-weight: bold;
      }
    }
  }
}
