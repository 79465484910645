.gradient_line {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 3px;
    background: linear-gradient(
      180deg,
      theme("colors.white") 18.75%,
      theme("colors.malibu") 100%
    );
  }
}
